@import '../../../../theme/newVariables.scss';

.partTitle {
  margin-left: $az-spacing-zero;
  font-family: $font-az-cond;
  font-size: $font-size-36;
  line-height: $az-spacing-44;
  text-transform: uppercase;
  font-weight: 500;

  @media #{$small-and-down} {
    font-size: $font-size-24;
    line-height: $az-spacing-m;
  }
}

.partTitleProductRecommendationCertona {
  padding-left: $az-spacing-xs;
  padding-top: $az-spacing-m;
  font-size: $font-size-24;
}

.separator {
  width: $az-spacing-84;
  height: $az-spacing-5xs;
  background-color: $az-medium-orange;
  transform: skewX(-30deg);
}

.thresholdHeaderText {
  font-family: $font-az-helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: $az-spacing-xxs;
  line-height: $az-spacing-s;
  text-transform: capitalize;
  color: $az-grey-6;

  @media #{$small-and-down} {
    margin-top: 32px;
    font-size: $az-spacing-14;
    line-height: 20px;
  }
}

.verticalStackedBlock {
  .cardsList {
    display: grid;
    grid-gap: 8px;
    margin-top: 24px;

    @media #{$small-and-down} {
      margin-top: 20px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (min-width: 720px) and (max-width: 1599px) {
      grid-template-columns: repeat(auto-fill, 160px);
    }

    @media #{$extra-large-and-up-max} {
      grid-template-columns: repeat(auto-fill, 192px);
    }

    .cardItem {
      & > a {
        display: block;
      }
    }
  }
}

.horizontalStackedBlock {
  .cardsList {
    display: grid;
    grid-gap: $az-spacing-4xs;

    @media #{$small-and-down} {
      margin-top: $az-spacing-xxs;
      grid-template-columns: repeat(1, 1fr);
    }

    @media #{$medium-only} {
      grid-template-columns: repeat(auto-fill, 343px);
    }

    @media #{$large-and-up} {
      margin-top: 24px;
      grid-template-columns: repeat(auto-fill, 360px);
    }
  }

  &.inDrawer {
    .partTitle {
      font-family: $font-az-cond;
      font-style: normal;
      font-weight: 800;
      font-size: $font-size-24;
      line-height: $az-spacing-m;
    }

    .cardsList {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
