@import '@/theme/newVariables';

.horizontalPartCardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.horizontalPartCard {
  display: flex;
  justify-content: space-between;
  padding: $az-spacing-4xs;
  background-color: $az-white;
  border-radius: $az-spacing-5xs;
  transition: box-shadow 250ms, transform 250ms;
  height: 100%;
  box-shadow: $az-box-shadow-gray-1;

  @media #{$medium-only} {
    min-width: 332px;
    max-width: 388px;
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: $az-spacing-zero $az-spacing-2 $az-spacing-5 $az-spacing-zero rgba(0, 0, 0, 0.07),
        $az-spacing-zero $az-spacing-5xs $az-spacing-5 $az-spacing-zero rgba(0, 0, 0, 0.06),
        $az-spacing-zero $az-spacing-1 $az-spacing-6 $az-spacing-zero rgba(0, 0, 0, 0.1);
      transform: scale(1.06);
    }
  }

  .gridContainerPartCard {
    display: flex;
    gap: $az-spacing-4xs;
    width: 100%;
  }

  .gridContainerPartCardWithoutCta {
    gap: $az-spacing-xxs;
  }

  .addToCartButtonGridArea {
    display: flex;
    justify-content: flex-end;
  }

  .certonaPartImgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 64px;
  }

  .certonaPartImage {
    height: $az-spacing-xxxl;
    width: $az-spacing-xxxl;

    @media #{$small-and-down} {
      height: $az-spacing-44;
      width: $az-spacing-44;
    }
  }

  .productInfo {
    display: flex;
    flex-direction: column;
    gap: $az-spacing-2;
    width: 100%;

    @media #{$small-and-down} {
      gap: 0;
    }
  }
  .priceAndRatingContainer {
    display: flex;
    flex-direction: row;
    gap: $az-spacing-5xs;
    width: 100%;
    align-items: center;
  }

  .nameAndCTAContainer {
    display: flex;
    height: $az-spacing-45;
    align-items: center;
    gap: $az-spacing-xxs;
  }

  .partText,
  .pricingContainer {
    color: $az-black;
    margin: 0 $az-spacing-xxxs;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .partText,
  .pricingContainerRecommendation {
    margin: 5px 0 0 0;
  }

  .ratingNumber {
    margin-left: $az-spacing-4xs;
    margin-right: $az-spacing-5xs;
  }

  @media #{$small-and-down} {
    .noTotalPriceRatingNumber {
      margin-left: $az-spacing-zero;
    }
  }

  .ratingReviews {
    margin-left: $az-spacing-5;
  }

  .ratingsContainer {
    color: $az-black;
    gap: $az-spacing-5xs;
    white-space: nowrap;
  }

  .price {
    text-align: right;
    font-family: $font-az-helvetica;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #202124;
  }

  .pricingInfo {
    white-space: nowrap;
    font-family: $font-az-helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-12;
    line-height: $az-spacing-22;
    color: $az-grey-11;
  }

  .addtoCartButton {
    font-family: $font-az-cond-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #202124;
    line-height: 17px;
    padding: $az-spacing-6 13.5px;
    border-radius: 20px !important;
    text-transform: uppercase;
    width: $az-spacing-77;
    height: $az-spacing-33;
  }

  .addedTocartButtonstyles {
    padding: 3px 7.5px !important;
    width: 90px;
  }

  .addtoCartButtonOosOrDoesNotFit {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    border-radius: 50%;
    height: $az-spacing-45;
    width: $az-spacing-45;
  }

  .addtoCartButtonMobile {
    @media #{$small-and-down} {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
      margin: 0px;
      border-radius: 50%;
      height: $az-spacing-45;
      width: $az-spacing-45;
    }
  }

  .addtoCartButtonMobileForOos {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    border-radius: 50%;
    height: $az-spacing-45;
    width: $az-spacing-45;
  }

  .configSkuMobileCTA {
    @media #{$small-and-down} {
      height: $az-spacing-33;
      padding: $az-spacing-4xs $az-spacing-xxxs;
      min-height: unset;
      width: auto;
      border-radius: 18px;
    }
  }

  .addtoCartText {
    padding-left: $az-spacing-5xs;
  }

  .totalPrice {
    color: $az-grey-3;
    margin-left: $az-spacing-5xs;
    text-decoration: line-through;
  }

  .emptyRatings {
    height: $az-spacing-22;
  }

  .partLabel {
    font-size: $font-size-14;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: $az-grey-7;
    overflow: hidden;
    align-self: center;
    display: -webkit-box;
    height: calc(19px * 3);

    @supports (-webkit-line-clamp: 2) {
      height: auto;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }

  .certonaPartLabel {
    font-family: $font-az-helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: $az-spacing-14;
    line-height: 18px;
    color: #202124;
    text-align: left;
  }

  .certonaPartLabelForOos {
    color: $az-black-1;
  }

  .partLabelRecommendation {
    width: 100%;
    height: calc(19px * 2);
    min-height: 36px;

    @supports (-webkit-line-clamp: 2) {
      height: auto;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}

.horizontalPartCardForOos {
  width: 343px;
  height: 89px;

  @media #{$medium-only} {
    width: 332px;
  }

  @media #{$small-and-down} {
    width: 312px;
  }
}

.horizontalPartCardForCart {
  @media #{$large-and-up} {
    min-width: 332px !important;
    max-width: 388px;
  }
}

.horizontalScrolled {
  @media #{$small-and-down} {
    width: 312px;
  }

  @media #{$large-and-up} {
    min-width: 332px;
    width: 343px;
    max-width: 388px;
  }
}

.horizontalStacked {
  @media #{$large-and-up} {
    width: 360px;
  }
}

.horizontalPartCardContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.shopAll {
  .shopAllText {
    font-size: 16px;
    line-height: 24px;
    padding: 8px;
    white-space: nowrap;
  }
}

.starcLargeBodyOverride {
  @media #{$medium-and-up} {
    font-size: 18px;
  }
}

.starcSubtitleRegularOverride {
  line-height: 0;

  @media #{$medium-and-up} {
    font-size: 12px;
  }
}
