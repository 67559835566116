@import '@/theme/newVariables';

.newLargePartCard {
  background-color: $az-white;
  border: solid $az-grey-2;
  border-width: $az-spacing-1 $az-spacing-1 $az-spacing-1 $az-spacing-zero;
  text-align: $cdirection;
  height: 100%;
  padding: $az-spacing-xxxs;
  border-radius: $az-spacing-5xs;
  box-shadow: 0px 1px 5px rgba(32, 33, 36, 0.12), 0px 2px 2px rgba(32, 33, 36, 0.14);
  transition: box-shadow 250ms, transform 250ms;
  display: flex;
  &.partCardCTAEnabled {
    min-height: 268px;
  }
  @media (hover: hover) {
    &:hover {
      box-shadow: $az-spacing-zero $az-spacing-2 $az-spacing-5 $az-spacing-zero rgba(0, 0, 0, 0.07),
        $az-spacing-zero $az-spacing-5xs $az-spacing-5 $az-spacing-zero rgba(0, 0, 0, 0.06),
        $az-spacing-zero $az-spacing-1 $az-spacing-6 $az-spacing-zero rgba(0, 0, 0, 0.1);
      transform: scale(1.06);
      cursor: pointer;
    }
  }

  .certonaPartImage {
    width: 100px;
    height: 100px;
  }

  .addtoCartButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-az-cond-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #202124;
    line-height: 17px;
    border-radius: 20px !important;
    text-transform: uppercase;
    height: $az-spacing-33;
    padding: 8px 16px !important;
    width: 100%;
  }

  .addedTocartButtonstyles {
    padding: 6px 16px !important;
  }

  .priceUnavailable {
    margin-left: 0;
    justify-content: start;
    padding-top: $az-spacing-4xs;
    padding-bottom: $az-spacing-5xs;
    .pricingInfo {
      line-height: 20px;
    }

    @media #{$small-and-down} {
      margin-left: 4px;
    }
  }

  .addtoCartContainer {
    margin: 12px 0 0;
    max-width: 100%;
  }

  .plusCircleImg {
    padding-top: $az-spacing-3;
    padding-right: 3.5px;
    padding-left: $az-spacing-10;
  }

  .loadingIndicator {
    padding-left: $az-spacing-35;
    padding-bottom: $az-spacing-11;
  }

  .partLabel {
    min-height: $az-spacing-70;
    font-family: $font-az-helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: $az-spacing-14;
    line-height: 19.6px;
    color: #202124;
    text-align: $ldirection;
    font-family: $font-az-helvetica;
    font-size: $font-size-14;
    font-stretch: normal;
    letter-spacing: normal;
    overflow: hidden;
    align-self: center;
    display: -webkit-box;
    height: calc(19px * 3);
    &.partLabelAddToCart {
      min-height: unset;
    }
    @supports (-webkit-line-clamp: 2) {
      height: auto;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }

  .pricingContainer {
    color: $az-black;
    margin: 0;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    padding-top: 0;
  }

  .pricingandReviewContainer {
    margin-bottom: 2px;
  }

  .ratingNumber {
    margin-right: $az-spacing-5xs;
  }

  .isPDPRatingNumber {
    margin-left: 8px;
  }

  .ratingReviews {
    margin-left: $az-spacing-5;
  }

  .ratingsContainer {
    color: $az-black;
  }

  .pricingInfo {
    font-family: $font-az-helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-12;
    line-height: $az-spacing-22;
    color: $az-grey-11;
  }

  .totalPrice {
    color: $az-grey-3;
    margin-left: $az-spacing-5xs;
    text-decoration: line-through;
  }

  .emptyRatings {
    height: $az-spacing-22;
  }

  .emptyPrice {
    height: $az-spacing-s;
  }

  .ratingsContainerPartCard {
    padding: unset;
    max-height: 19px;
  }

  .ratingsContainerPartCardMargin {
    margin: 0;
  }

  .ratingsContainerPartCardwithoutprice {
    padding: $az-spacing-2 $az-spacing-xxxs $az-spacing-6 $az-spacing-23 !important;
    &.ratingsContainerPartCardMargin {
      padding-left: 0 !important;
    }
  }

  .contentContainer {
    padding-right: $az-spacing-22 !important;
    padding-left: 0 !important;
  }

  .imageContainer {
    padding: 0 $az-spacing-4xs $az-spacing-4xs $az-spacing-4xs;
  }
}

.partProductRecommendationCertona {
  min-height: unset;
  height: $az-spacing-110;
  width: $az-spacing-300 !important;
}

.price {
  text-align: right;
  font-family: $font-az-helvetica;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 25.2px !important;
  color: #202124;
}

.addtoCartText {
  padding-left: $az-spacing-5xs;
}

.starcSubtitleRegularOverride {
  @media #{$small-and-down} {
    line-height: 14px;
  }

  @media #{$medium-and-up} {
    font-size: 12px;
  }
}

.starRatingContainer {
  @media #{$medium-and-up} {
    transform: translateY(-1px);
  }
}
