@import '../../../theme/newVariables.scss';

.select {
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 26px 12px 9px 16px;
  background-color: $az-white;
  color: $az-grey-14;
}

.select.disabled {
  cursor: default;
}

.select[multiple] {
  height: auto;
}

.select:not([multiple]) option,
.select:not([multiple]) optgroup {
  background-color: #fff;
}

.select.select {
  padding-right: 24px;
}

.selectMenu {
  height: auto;
  overflow: hidden;
  min-height: 1.1876em;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 0;
  position: absolute;
  pointer-events: none;
}

.icon.disabled {
  color: rgba(0, 0, 0, 0.26);
}

.iconOpen {
  transform: rotate(180deg);
}

.nativeInput {
  left: 0;
  width: 100%;
  bottom: 0;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
