@import '../../../theme/newVariables';
@import '../../../theme/variables.scss';

.ratingNumber {
  margin-left: $az-spacing-4xs;
}

.ratingReviews {
  margin-left: 5px;
}

.ratingsContainer {
  color: $az-light-black;
}
