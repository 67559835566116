@import '../../../theme/newVariables.scss';

.startingAt {
  font-size: 18px;
  font-family: 'HelveticaNeue-Bold', sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.76px;
  line-height: 26px !important;
  text-transform: uppercase;
  margin-right: 8px;
  margin-left: 5px;
  color: #202124;

  @media #{$small-and-down} {
    font-size: 14px;
    line-height: 20px !important;
  }
}

.primary > .priceIcon {
  color: $az-black;
  vertical-align: top;
  line-height: 1;
}

.strikethrough > .priceIcon {
  color: $az-black;
  vertical-align: top;
  line-height: 10px;
  font-size: 16px;
}

.secondary > .priceIcon {
  font-size: $font-size-14;
  line-height: 1;
  color: $az-black;
  vertical-align: top;
}

.primary > .price {
  color: $az-black;
  font-size: $font-size-45;
  line-height: 0.8;
}

.secondary > .price {
  font-size: $font-size-25;
  color: $az-black;
  line-height: 0.8;
}

.strikethrough > .price {
  color: $az-black;
  font-size: $font-size-36;
  line-height: 24px;
}

.primary > .priceSub {
  color: $az-black;
  font-size: $font-size-16;
  vertical-align: top;
  line-height: 1;

  @media #{$small-and-down} {
    font-size: $font-size-16;
  }
}

.strikethrough > .priceSub {
  color: $az-black;
  font-size: $font-size-16;
  vertical-align: top;
  line-height: 10px;
}

.secondary > .priceSub {
  font-size: $font-size-14;
  color: $az-black;
  line-height: 1;
  vertical-align: top;
}

.small > .priceIcon {
  font-size: $font-size-10;
  line-height: 1;
  color: $az-black;
  vertical-align: top;
}

.small > .price {
  font-size: $font-size-16;
  color: $az-black;
  line-height: 0.8;
  vertical-align: top;
}

.small > .priceSub {
  font-size: $font-size-10;
  color: $az-black;
  line-height: 1;
  vertical-align: top;
}

.salePrimary > .sale {
  font-size: $font-size-18;
  font-weight: 900;
  line-height: 1.11;
  color: $az-medium-orange;
}

.salePrimary > .priceAmount {
  font-size: $font-size-18;
  color: $az-grey-3;
  text-decoration: line-through;
}

/* For Sale amount we need to update CSS */
.saleSecondary > .sale {
  font-size: $font-size-12;
  font-weight: 900;
  line-height: 1.11;
  color: $az-medium-orange;
}

.saleSecondary > .priceAmount {
  font-size: $font-size-12;
  color: $az-grey-3-point-5;
  text-decoration: line-through;
}

.wasPriceComponent {
  text-decoration: line-through;
  color: $az-grey-3-point-5;
  text-align: left;
  font-size: $font-size-12;

  span {
    color: $az-grey-3-point-5;
  }
}

.regularPriceStrikeThrough {
  font-family: $font-az-helvetica, 'Helvetica Neue', Helvetica, Roboto, Arial;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: 700;
  @media #{$small-and-down} {
    font-size: $font-size-14;
  }
}

.secondary > .coreChargeTitleColor {
  color: $az-grey-4;
}

.secondary > .breakdownTitle {
  font-size: 12px;
}

.centSymbol {
  color: transparent;
  font-size: $az-spacing-point-one;
}

.regularPriceStrikeThroughCentSymbol {
  color: $az-black;
  font-size: $font-size-14;
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.priceDetailAlignRight {
    align-items: flex-end;
  }
}
.strikeThruPriceWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cartPriceWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.priceDetailAlignRight {
    align-items: flex-end;
  }
}

.lowercase {
  text-transform: none;
}

.eachLabel {
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #5b5d5b;
}
.wasPriceRightAlign {
  text-align: right;
  & .priceAmount {
    font-size: $az-spacing-xxxs;
  }
}

.freeLabel {
  color: $az-color-success;
  font-weight: 800;
  size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-family: $font-az-cond;
}
