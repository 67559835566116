@import '../../theme/variables.scss';

.root {
  display: inline-flex;
  position: relative;
  font-size: 24px;
  text-align: left;
  pointer-events: none;
  vertical-align: middle;
  gap: 2px;
}

.icon {
  display: flex;
  font-size: 12px;
}
