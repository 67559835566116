@import '../../../theme/newVariables';

.swipeableContainer {
  max-width: 1600px;
  margin: 0 auto;
  padding: $az-spacing-zero;
}

.carousel {
  margin-top: $az-spacing-xxxl;

  .list_parts {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;

    .cards {
      width: 100%;
      display: flex;
      overflow: hidden;
      padding: var(--st-unit-4);
      min-height: 260px;
      margin: 24px 0 0;

      > li {
        max-width: 100%;
        width: $az-spacing-160;
        flex-basis: auto;
        flex-shrink: 0;

        &:not(:last-child) {
          margin-right: $az-spacing-xxs;
        }

        &:first-child {
          margin-left: $az-spacing-xxs;
          @media #{$small-and-down} {
            margin-left: $az-spacing-2;
          }
        }
      }

      @media #{$medium-only} {
        max-width: 93vw;
      }
    }

    .leftArrow {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: $az-spacing-1; // align center does not work with min-height on IE unless there is a height specified

      @media #{$medium-only} {
        margin-right: 0;
      }

      .chevron {
        transform: rotate(180deg);
      }
    }

    .rightArrow {
      cursor: pointer;
      height: $az-spacing-1; // align center does not work with min-height on IE unless there is a height specified
      display: flex;
      justify-content: center;
      align-items: center;
      color: $az-black;

      @media #{$medium-and-up} {
        padding-left: $az-spacing-31;
      }
    }

    .greyBgrightArrow {
      @media #{$medium-only} {
        background-color: $az-grey-1;
      }
    }

    @media #{$small-and-down} {
      .leftArrow,
      .rightArrow {
        display: none;
      }
      .cards {
        padding-left: 0;
      }
    }
  }
}

.greyBackground {
  background-color: $az-grey-1 !important;
}

.partTitle {
  margin-left: $az-spacing-zero;
  font-family: $font-az-cond;
  font-size: $font-size-36;
  letter-spacing: 0.02rem;
  line-height: 1.12;
  text-transform: uppercase;
  font-weight: 500;

  @media #{$small-and-down} {
    font-size: $font-size-32;
  }
}

.partTitleProductRecommendationCertona {
  padding-left: $az-spacing-xs;
  padding-top: $az-spacing-m;
  font-size: $font-size-24;
}

.certonaCarousel {
  margin-top: $az-spacing-zero !important;
}

.miniCartMargin {
  margin-top: 0;
}

.separator {
  width: $az-spacing-84;
  height: $az-spacing-5xs;
  background-color: $az-medium-orange;
  transform: skewX(-30deg);
}
