@import '../../../theme/newVariables';

.part {
  background-color: $az-white;
  border: solid $az-grey-2;
  border-width: $az-spacing-1 $az-spacing-1 $az-spacing-1 $az-spacing-zero;
  text-align: $cdirection;
  transition: box-shadow 250ms, transform 250ms;
  min-height: 167px;
  height: 100%;
  box-shadow: $az-box-shadow-gray-1;

  @media (hover: hover) {
    &:hover,
    &:focus-within {
      box-shadow: $az-spacing-zero $az-spacing-2 $az-spacing-5 $az-spacing-zero rgba(0, 0, 0, 0.07),
        $az-spacing-zero $az-spacing-5xs $az-spacing-5 $az-spacing-zero rgba(0, 0, 0, 0.06),
        $az-spacing-zero $az-spacing-1 $az-spacing-6 $az-spacing-zero rgba(0, 0, 0, 0.1);
      transform: scale(1.06);
    }
  }

  .partImage {
    width: 100px;
    height: 100px;
  }

  .certonaPartImage {
    width: 65px;
    height: 65px;
  }

  .addtoCartCta {
    display: flex;
    align-self: start;
    font-family: $font-az-helvetica;
    font-style: normal;
    font-weight: 500;
    font-size: $az-spacing-14;
    line-height: $az-spacing-22;
    text-align: center;
    color: $az-black;

    .shapePlusCircle {
      display: block;
      visibility: visible;
    }
  }
  .addToCartHoverCta {
    display: flex;
    align-self: start;
    font-family: $font-az-helvetica;
    font-style: normal;
    font-weight: 500;
    font-size: $az-spacing-14;
    line-height: $az-spacing-22;
    text-align: center;
    color: $az-grey-14;
    cursor: pointer;

    .shapePlusCircle {
      display: block;
      visibility: visible;
    }
  }

  .partCard {
    text-transform: unset;
    margin: 0 auto;

    &:hover {
      background-color: inherit;
    }
  }

  .addtoCartContainer {
    margin-top: $az-spacing-10;
  }

  .plusCircleImg {
    padding-top: $az-spacing-3;
    padding-right: 3.5px;
    padding-left: $az-spacing-10;
  }

  .loadingIndicator {
    padding-left: $az-spacing-35;
    padding-bottom: $az-spacing-11;
  }

  .partLabel {
    font-family: $font-az-helvetica;
    font-size: $font-size-14;
    margin: 0 $az-spacing-xxxs;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: $az-grey-7;
    overflow: hidden;
    align-self: center;
    display: -webkit-box;
    height: calc(19px * 3);
    min-height: $az-spacing-57;

    @supports (-webkit-line-clamp: 2) {
      height: auto;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }

  .partLabelRecommendation {
    margin: 0;
    height: calc(19px * 2);
    min-height: 36px;

    @supports (-webkit-line-clamp: 2) {
      height: auto;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .certonaPartLabel {
    font-family: $font-az-helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #202124;
    text-align: left;
  }

  .partText,
  .pricingContainer {
    color: $az-black;
    margin: 0 $az-spacing-xxxs;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .partText,
  .pricingContainerRecommendation {
    margin: 5px 0 0 0;
  }

  .pricingContainer {
    padding-top: 0;
  }

  .pricingInfo {
    font-family: $font-az-helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-12;
    line-height: $az-spacing-22;
    color: $az-grey-11;
  }

  .totalPrice {
    color: $az-grey-3;
    margin-left: 4px;
    text-decoration: line-through;
  }

  .emptyRatings {
    height: $az-spacing-22;
  }

  .ratingsContainerPartCard {
    padding: 2px 12px 10px 12px !important;
  }

  .contentContainer {
    padding-right: 22px !important;
    padding-left: 0 !important;
  }

  .imageContainer {
    padding-right: 0 !important;
  }
}

.partProductRecommendationCertona {
  min-height: unset;
  height: 110px;
  width: 300px !important;
}

.price {
  text-align: right;
}
