@import '../../theme/newVariables';

.root {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  font-size: inherit;
}

.certonaPartCard {
  height: 13px;
  width: 13px;
}

.svgForOos {
  width: 14px;
  height: 14px;

  @media #{$small-and-down} {
    width: 12px;
    height: 12px;
  }
}
