@import '../../../../theme/newVariables.scss';

.partTitle {
  margin-left: $az-spacing-zero;
  font-family: $font-az-cond;
  font-size: $font-size-36;
  letter-spacing: 0.02rem;
  line-height: $az-spacing-44;
  text-transform: uppercase;
  font-weight: 500;

  @media #{$small-and-down} {
    font-size: $font-size-32;
  }
}

.swipeableContainer {
  max-width: 1600px;
  margin: $az-spacing-xxxs auto $az-spacing-xxs auto;
  padding: $az-spacing-zero;
  display: flex;
  flex-direction: column;
  @media #{$medium-and-down} {
    margin: $az-spacing-xs auto $az-spacing-xxs auto;
  }
}

.swipeableContainerForOos {
  max-width: 1560px;
  height: 92px;

  @media #{$large-and-up} {
    width: 1250px;
  }
}

.partTitleProductRecommendationCertona {
  padding-left: $az-spacing-xs;
  padding-top: $az-spacing-m;
  font-size: $font-size-24;
}

.separator {
  width: $az-spacing-84;
  height: $az-spacing-5xs;
  background-color: $az-medium-orange;
  transform: skewX(-30deg);
}

.thresholdHeaderText {
  font-family: $font-az-helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: $az-spacing-xxs;
  line-height: $az-spacing-s;
  text-transform: capitalize;
  color: $az-grey-6;

  @media #{$small-and-down} {
    font-size: $az-spacing-14;
    line-height: 20px;
  }
}

.marginForThresholdHeader {
  @media #{$small-and-down} {
    margin: 0 0 20px !important;
  }
}

.oosNoMargin {
  margin: 0 !important;
}

.oosArrowAlignment {
  transform: translateY($az-spacing-neg-12);
}

.arrowAlignement {
  top: 42% !important;
}

.leftArrowVertical {
  left: $az-spacing-neg-6;
}

.leftArrowHorizontal {
  left: $az-spacing-neg-10;
}

.scrollingContainer {
  position: relative;
  overflow-x: hidden;
  margin: 0px 0px -8px -12px;
  .leftArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-25.5%);
    cursor: pointer;
    z-index: 1;

    .chevronImg {
      margin: 0 !important;
      transform: rotate(180deg);
    }

    .chevronImgHover {
      top: -2px !important;
      right: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      opacity: 0;
      margin: 0 !important;
      transform: rotate(180deg);
      position: absolute;
      object-fit: cover;
    }

    @media (hover: hover) {
      &:hover .chevronImgHover {
        opacity: 1;
      }
    }

    &.hiddenArrow {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }

  &.verticalScrollBlock {
    padding-left: $az-spacing-xxxs;
    .cardsList {
      margin-left: -8px !important;
      margin: 0;
      display: flex;
      gap: $az-spacing-4xs;
      padding: var(--st-unit-6) var(--st-unit-2) var(--st-unit-5) var(--st-unit-2);
      overflow: hidden;

      .newCardItem {
        flex: 0 0 160px;

        @media #{$small-and-down} {
          flex: 0 0 158px;
        }
      }

      .cardItem {
        @media #{$small-and-down} {
          flex: 0 0 calc(50% - $az-spacing-3);
        }

        @media #{$medium-and-up} {
          flex: 0 0 160px;
        }

        & > a {
          display: block;
        }
      }
    }
  }

  &.horizontalScrollBlock {
    .leftArrow,
    .rightArrow {
      @media #{$small-and-down} {
        display: none;
        visibility: hidden;
        opacity: 0;
      }
    }

    .cardsList {
      display: flex;
      gap: $az-spacing-4xs;
      margin: 0;
      padding: var(--st-unit-4) 0 var(--st-unit-2) var(--st-unit-3);
      overflow: hidden;

      @media #{$small-and-down} {
        flex: 0 0 calc(50% - $az-spacing-3);
        overflow: auto;
      }

      @media #{$medium-only} {
        flex: 0 0 calc(33.33% - $az-spacing-5xs);
      }

      @media #{$extra-large-and-up-mid} {
        flex: 0 0 calc(25% - $az-spacing-5-point-5);
      }

      @media #{$large-mid-and-up} {
        flex: 0 0 calc(20% - $az-spacing-6);
      }

      & > a {
        display: block;
        height: $az-spacing-261;
      }
    }

    .increasedPadding {
      padding-top: var(--st-unit-6);
    }

    .cartCardList {
      padding-bottom: var(--st-unit-3);
    }

    .oosCardList {
      padding: $az-spacing-5xs $az-spacing-14 $az-spacing-4xs $az-spacing-14;

      .newCardItem {
        height: $az-spacing-92;
      }
    }
  }

  .rightArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-18%);
    right: $az-spacing-neg-6;
    cursor: pointer;

    @media #{$medium-only} {
      right: $az-spacing-neg-10;
    }

    .chevronImg {
      margin: 0 !important;
    }

    .chevronImgHover {
      top: -2px !important;
      right: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      opacity: 0;
      margin: 0 !important;
      position: absolute;
      object-fit: cover;
    }

    @media (hover: hover) {
      &:hover .chevronImgHover {
        opacity: 1;
      }
    }

    &.hiddenArrow {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }
}

.scrollingContainerOverflow {
  overflow-x: unset;
}

.thresholdContainer {
  margin-bottom: var(--st-unit-5);
  @media #{$small-and-down} {
    margin-bottom: var(--st-unit-0);
  }
}
